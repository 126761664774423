<!-- eslint-disable func-names -->
<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div class="one-pb">
    <div
      class="icon-svg icon-plu"
      :class="{ active: visible }"
      @click="visible = !visible"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
      />
    </div>
    <div
      class="icon-svg icon-minu"
      :class="{ active: visible }"
      @click="visible = !visible"
    >
      <feather-icon
        icon="MinusIcon"
        size="18"
      />
    </div>
    <div class="title-pb">
      <label>
        <b-form-checkbox
          v-model="selectAll"
          class="custom-control-primary"
        >
          <span align="center">
            <feather-icon
              icon="FolderIcon"
              size="18"
              class="text-warning"
            />
            {{ ob.name }}
          </span>
        </b-form-checkbox>
      </label>
    </div>
    <b-collapse
      v-model="visible"
    >
      <label
        v-for="(itemChild, index1) in ob.listChild"
        :key="index1"
        class="title-pb-child"
      >
        <b-form-checkbox
          v-model="selected"
          :value="itemChild.id"
          class="custom-control-primary"
          number
        >
          <span>
            <feather-icon
              icon="FolderIcon"
              size="18"
              class="text-warning"
            />
            {{ itemChild.name }}
          </span>
        </b-form-checkbox>
      </label>
    </b-collapse>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  VBToggle,
  BCollapse,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
// import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BCollapse,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [general],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    ob: Object,
  },
  data() {
    return {
      selected: [],
      visible: false,
    }
  },
  computed: {
    selectAll: {
      get() {
        // eslint-disable-next-line eqeqeq
        return this.ob.listChild ? this.selected.length == this.ob.listChild.length : false
      },
      set(value) {
        const selected = []

        if (value) {
          this.ob.listChild.forEach(user => {
            selected.push(user.id)
          })
        }

        this.selected = selected
      },
    },
  },
  watch: {

  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.text-warning{
  fill: #ff9f43 !important
}
.title-pb-child{
  padding-left: 26px
}
.one-pb{
  padding-left: 26px;
  position: relative;
  .title-pb-child{
    display: block;
  }
}
span{
  display: flex;
  align-items: center;
  svg{
    margin-right: 6px;
  }
}
.icon-svg{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 1px solid #6e6b7b;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 3px;
  svg{
    margin-right: 0;
  }
}
.icon-plu{
  display: flex;
}
.icon-plu.active{
  display: none;
}
.icon-minu{
  display: none;
}
.icon-minu.active{
  display: flex;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
