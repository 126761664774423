<!-- eslint-disable func-names -->
<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="8">
          <Fieldset id="shelfinfo" :title="$t('Departments')">
            <b-row>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Department name') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Code"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.code"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Code')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Created At') + `:`" label-cols-md="12">
                  <label for="date-to" class="d-block">
                    <flat-pickr
                      v-model="model.date_to"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        minDate: 'today',
                        dateFormat: 'Y-m-d H:i:ss',
                      }"
                      placeholder="To"
                    />
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="DateTo"
                    rules="required"
                  >
                    <input id="date-to" v-model="model.date_to" type="hidden">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="d-flex align-items-center">
                <b-form-group class="align-center-seclect">
                  <label class="an-hien">{{ $t('Display on/off') }}</label>
                  <b-form-checkbox
                    v-model="model.is_active"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  {{ $t('Add') }}
                </b-button>
              </b-col>
            </b-row>
          </Fieldset>
        </b-col>
        <b-col md="4">
          <div class="position-sticky top">
            <Fieldset id="permissions" :title="$t('List of permissions')">
              <DepartmentsItem
                v-for="(item, index) in ListPB" :key="index"
                :ob="ListPB[index]"
              />
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  // BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
// import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DepartmentsItem from './DepartmentsItem.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    flatPickr,
    DepartmentsItem,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      avatarText,
      required,
      model: {
        name: null,
        qrcode: null,
        code: null,
        place: null,
        tag: null,
        quantity: null,
        position: null,
        is_active: true,
      },
      items: [],
      isQrCode: false,
      timeout: null,
      users: [
        { id: '1', name: 'Shad Jast', email: 'pfeffer.matt@yahoo.com' },
        { id: '2', name: 'Duane Metz', email: 'mohammad.ferry@yahoo.com' },
        { id: '3', name: 'Myah Kris', email: 'evolkman@hotmail.com' },
        { id: '4', name: 'Dr. Kamron Wunsch', email: 'lenora95@leannon.com' },
      ],
      selected: [],
      ListPB: [
        {
          name: 'Phòng ban',
          id: 1,
          listChild: [
            {
              name: 'xem phòng ban',
              id: 11,
            },
            {
              name: 'thêm phòng ban',
              id: 12,
            },
            {
              name: 'sửa phòng ban',
              id: 13,
            },
            {
              name: 'xóa phòng ban',
              id: 14,
            },
          ],
        },
        {
          name: 'khách hàng',
          id: 2,
          listChild: [
            {
              name: 'xem khách hàng',
              id: 21,
            },
            {
              name: 'thêm khách hàng',
              id: 22,
            },
            {
              name: 'sửa khách hàng',
              id: 23,
            },
            {
              name: 'xóa khách hàng',
              id: 24,
            },
          ],
        },
        {
          name: 'người quản trị',
          id: 3,
          listChild: [
            {
              name: 'xem người quản trị',
              id: 31,
            },
            {
              name: 'thêm người quản trị',
              id: 32,
            },
            {
              name: 'sửa người quản trị',
              id: 33,
            },
            {
              name: 'xóa người quản trị',
              id: 34,
            },
          ],
        },
        {
          name: 'Phân quyền',
          id: 4,
          listChild: [
            {
              name: 'xem phân quyền',
              id: 41,
            },
            {
              name: 'thêm phân quyền',
              id: 42,
            },
            {
              name: 'sửa phân quyền',
              id: 43,
            },
            {
              name: 'xóa phân quyền',
              id: 44,
            },
          ],
        },
        {
          name: 'giao việc',
          id: 5,
          listChild: [
            {
              name: 'xem giao việc',
              id: 51,
            },
            {
              name: 'thêm giao việc',
              id: 52,
            },
            {
              name: 'sửa giao việc',
              id: 53,
            },
            {
              name: 'xóa giao việc',
              id: 54,
            },
          ],
        },
      ],
    }
  },
  computed: {
  },
  watch: {
    // eslint-disable-next-line func-names
    'model.code': function () {
      this.isQrCode = false
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.isQrCode = true
      }, 10)
    },
  },
  created() {
    this.loadList()
  },
  methods: {
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          // eslint-disable-next-line array-callback-return
          res.map(x => {
            const item = {
              id: x.slug,
              label: x.name,
              slug: x.slug,
              idx: x.id,
              children: [],
            }
            if (x.children && x.children.length > 0) {
              // eslint-disable-next-line array-callback-return
              x.children.map(y => {
                const i = {
                  id: y.slug,
                  label: y.name,
                  slug: y.slug,
                  idx: y.id,
                }
                item.children.push(i)
              })
            }
            this.items.push(item)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    handlerChangeCategory() {
      // eslint-disable-next-line array-callback-return
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          // eslint-disable-next-line array-callback-return
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              address: this.model.address,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              name: this.model.name,
              phone: this.model.phone,
              address: this.model.address,
              translation: JSON.stringify(translation),
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/supplier`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create success',
                  },
                })
                this.$router.push('/warehouse-list/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
.box-list {
  margin-top: 2rem;
}
.text-right{
  margin-right: 0;
  margin-left: auto;
  display: inherit;
}
.an-hien{
  font-weight: bold;
  color: #6e6b7b;
  font-size: 14px;
}
</style>
<style lang="scss">
.align-center-seclect{
  .form-row{
    align-items: center;
  }
  &.form-group{
    margin-bottom: 0;
  }
}
.align-center-seclect > div{
  display: flex;
  align-items: center;
  label{
    margin-bottom: 0;
    margin-right: 16px;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
